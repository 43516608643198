import './src/css/index.css';

import 'swiper/css';
import 'swiper/css/pagination';
// import axios from 'axios';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(`This application has been updated. ` + `Reload to display the latest version?`);
  if (answer === true) {
    window.location.reload();
  }
};

// export function onClientEntry() {
//   axios.defaults.baseURL = '/api';
// }
